#af-form-352608519 .af-body .af-textWrap {
    width: 70%;
    display: block;
    float: right;
}

#af-form-352608519 .af-body .privacyPolicy {
    color: #000000;
    font-size: 11px;
    font-family: Verdana, sans-serif;
}

#af-form-352608519 .af-body a {
    color: #000000;
    text-decoration: underline;
    font-style: normal;
    font-weight: normal;
}

#af-form-352608519 .af-body input.text,
#af-form-352608519 .af-body textarea {
    background-color: #FFFFFF;
    border-color: #000000;
    border-width: 1px;
    border-style: solid;
    color: #000000;
    text-decoration: none;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    font-family: Verdana, sans-serif;
}

#af-form-352608519 .af-body input.text:focus,
#af-form-352608519 .af-body textarea:focus {
    background-color: #FFFAD6;
    border-color: #5E5E5E;
    border-width: 1px;
    border-style: solid;
}

#af-form-352608519 .af-body label.previewLabel {
    display: block;
    float: left;
    width: 25%;
    text-align: left;
    color: #4D4D4D;
    text-decoration: none;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    font-family: Verdana, sans-serif;
}

#af-form-352608519 .af-body {
    padding-bottom: 15px;
    padding-top: 15px;
    background-repeat: no-repeat;
    background-position: inherit;
    background-image: none;
    color: #000000;
    font-size: 11px;
    font-family: Verdana, sans-serif;
}

#af-form-352608519 .af-quirksMode {
    padding-right: 15px;
    padding-left: 15px;
}

#af-form-352608519 .af-standards .af-element {
    padding-right: 15px;
    padding-left: 15px;
}

#af-form-352608519 .buttonContainer input.submit {
    background-image: none;
    background-color: #0F77BD;
    color: #FFFFFF;
    text-decoration: none;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    font-family: Verdana, sans-serif;
}

#af-form-352608519 .buttonContainer input.submit {
    width: auto;
}

#af-form-352608519 .buttonContainer {
    text-align: right;
}

#af-form-352608519 button,
#af-form-352608519 input,
#af-form-352608519 submit,
#af-form-352608519 textarea,
#af-form-352608519 select,
#af-form-352608519 label,
#af-form-352608519 optgroup,
#af-form-352608519 option {
    float: none;
    position: static;
    margin: 0;
}

#af-form-352608519 div {
    margin: 0;
}

#af-form-352608519 form,
#af-form-352608519 textarea,
.af-form-wrapper,
.af-form-close-button,
#af-form-352608519 img {
    float: none;
    color: inherit;
    position: static;
    background-color: none;
    border: none;
    margin: 0;
    padding: 0;
}

#af-form-352608519 input,
#af-form-352608519 button,
#af-form-352608519 textarea,
#af-form-352608519 select {
    font-size: 100%;
}

#af-form-352608519 select,
#af-form-352608519 label,
#af-form-352608519 optgroup,
#af-form-352608519 option {
    padding: 0;
}

#af-form-352608519,
#af-form-352608519 .quirksMode {
    width: 100%;
    max-width: 335px;
}

#af-form-352608519.af-quirksMode {
    overflow-x: hidden;
}

#af-form-352608519 {
    background-color: #FFFFFF;
    border-color: #8C8C8C;
    border-width: 1px;
    border-style: none;
}

#af-form-352608519 {
    display: block;
}

#af-form-352608519 {
    overflow: hidden;
}

.af-body .af-textWrap {
    text-align: left;
}

.af-body input.image {
    border: none !important;
}

.af-body input.submit,
.af-body input.image,
.af-form .af-element input.button {
    float: none !important;
}

.af-body input.submit {
    white-space: inherit;
}

.af-body input.text {
    width: 100%;
    float: none;
    padding: 2px !important;
}

.af-body.af-standards input.submit {
    padding: 4px 12px;
}

.af-clear {
    clear: both;
}

.af-element label {
    text-align: left;
    display: block;
    float: left;
}

.af-element {
    padding-bottom: 5px;
    padding-top: 5px;
}

.af-form-wrapper {
    text-indent: 0;
}

.af-form {
    box-sizing: border-box;
    text-align: left;
    margin: auto;
}

.af-quirksMode .af-element {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.lbl-right .af-element label {
    text-align: right;
}

body {}

#af-form-352608519 .bodyText p {
    margin: 0 0 1em !important;
}

#af-form-352608519 .af-body {
    padding-top:
}

#af-form-352608519 .af-body .af-textWrap {
    width: 100% !important;
}

#af-form-352608519 .af-body .af-element {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

#af-form-352608519 .af-body .af-element:first-child {
    margin-top: 0 !important;
}

#af-form-352608519 .af-body label.previewLabel {
    font-weight: 700 !important;
    margin-top: 0.25rem !important;
    margin-bottom: .25rem !important;
}

#af-form-352608519 .af-body input.text,
#af-form-352608519 .af-body textarea {
    border-radius: 3px !important;
    box-sizing: border-box !important;
    color: #444444;
    font-size: 1rem;
    margin-bottom: 0.75rem !important;
    padding: 8px 12px !important;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
}

#af-form-352608519 .af-body select {
    width: 100%;
}

#af-form-352608519 .af-body .af-dateWrap select {
    width: 33%;
}

#af-form-352608519 .choiceList-radio-stacked {
    margin-bottom: 1rem !important;
    width: 100% !important;
}

#af-form-352608519 .af-element-radio {
    margin: 0 !important;
}

#af-form-352608519 .af-element-radio input.radio {
    display: inline;
    height: 0;
    opacity: 0;
    overflow: hidden;
    width: 0;
}

#af-form-352608519 .af-element-radio input.radio:checked~label {
    font-weight: 700 !important;
}

#af-form-352608519 .af-element-radio input.radio:focus~label {
    box-shadow: inset 0 0 0 2px rgba(25, 35, 70, .25);
}

#af-form-352608519 .af-element-radio input.radio:checked~label:before {
    background-color: #777777;
    border-color: #d6dee3;
}

#af-form-352608519 .af-element-radio label.choice {
    border: 1px solid #d6dee3;
    border-radius: 3px !important;
    display: block !important;
    font-weight: 300 !important;
    margin: 0.5rem 0 !important;
    padding: 1rem 1rem 1rem 2rem !important;
    position: relative;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
}

#af-form-352608519 .af-element-radio label.choice:before {
    background-color: #FFF;
    border: 1px solid #d6dee3;
    border-radius: 50%;
    content: '';
    height: 0.75rem;
    margin-left: -1.3rem;
    position: absolute;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    width: 0.75rem;
}

#af-form-352608519 .buttonContainer {
    box-sizing: border-box !important;
}

#af-form-352608519 .af-footer {
    box-sizing: border-box !important;
}

#af-form-352608519 .af-footer p {
    margin: 0 !important;
}

#af-form-352608519 input.submit,
#af-form-352608519 #webFormSubmitButton {
    border: none;
    border-radius: 3px !important;
    font-weight: bold;
    margin-top: 0.5rem !important;
    margin-bottom: 1rem !Important;
    padding: 0.6rem 1.5rem !important;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
}

#af-form-352608519 input.submit:hover,
#af-form-352608519 #webFormSubmitButton:hover {
    cursor: pointer;
    opacity: 0.8;
}

#af-form-352608519 input.text:hover {
    cursor: pointer;
    opacity: 0.8;
}

.poweredBy a,
.privacyPolicy p {
    color: #000000 !important;
    font-size: 0.65rem !important;
}